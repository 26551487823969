<template>
  <v-card min-height="800px">
    <v-tabs grow centered show-arrows v-model="tab" background-color="primary" dark>
      <v-tab href="#tab-1"> GÖRÜŞME DETAYLARI </v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="tab">
      <v-tab-item :value="'tab-1'">
        <v-container>
          <v-list>
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-content-between align-center">
                <strong>Görüşülen Öğretmen</strong>
                <v-chip label small outlined color="primary">{{
                  editedItem.MeetingPersonnels
                }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-if="editedItem.MeetingDate">
              <v-list-item-title class="d-flex justify-content-between align-center">
                <strong>Görüşme Tarihi</strong>
                <v-chip label small outlined color="success">{{
                  editedItem.MeetingDate | moment
                }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-if="editedItem.MeetingSubjects">
              <v-list-item-title class="d-flex justify-content-between align-center">
                <strong>Görüşme Konusu</strong>
                <v-chip label small outlined color="warning">{{
                  editedItem.MeetingSubjects
                }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-if="editedItem.MeetingPerson">
              <v-list-item-title class="d-flex justify-content-between align-center">
                <strong>Görüşmeyi Yapan</strong>
                <v-chip label small outlined color="error">{{ editedItem.MeetingPerson }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-if="editedItem.MeetingResult">
              <v-list-item-title class="d-flex justify-content-between align-center">
                <strong>Görüşme Sonucu</strong>
                <v-chip label small outlined color="amber">{{ editedItem.MeetingResult }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-if="editedItem.MeetingCompletedDate">
              <v-list-item-title class="d-flex justify-content-between align-center">
                <strong>Görüşme Tamamlanma Tarihi</strong>
                <v-chip label small outlined color="cyan">{{
                  editedItem.MeetingCompletedDate | moment
                }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-if="editedItem.Description">
              <v-list-item-title>
                <strong>Öğretmen Notu</strong>
              </v-list-item-title>
              <p>
                {{ editedItem.Description }}
              </p>
            </v-list-item-content>
          </v-list>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import ApiService from '@/core/services/api.service'
import moment from 'moment'

export default {
  name: 'meeting-detail',
  data() {
    return {
      id: this.$route.params.id,
      options: {},
      editedItem: {}
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('DD.MM.YYYY hh:mm') : ''
    }
  },
  methods: {
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Meeting/student-meeting', {
        sortBy: this.options.sortBy,
        descending: this.options.sortDesc,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page ?? 1,
        Query: { Id: this.id }
      })
        .then((data) => {
          this.editedItem = data.data.Results[0]
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
